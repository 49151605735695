// Home.js
import React,{useState} from 'react';

import '../../css/panel/dashboard.css'

function Home() {

  const handleContextMenu = (event) => {
    event.preventDefault();
  };
  return (
    <div id="dashboard-view" className='dashboard-view' onContextMenu={handleContextMenu}>
      <div className='dv-title'>Welcome the Avaland sea!</div>      

      <div className='dv-title-2'>We're in pre-launch season.</div>
      
      

      <div className='dv-box-2'>
        <div className='dv-box2-title'>Discord News</div>
        <div className='dv-box2-box'>
          <div>
            <div className='dv_box2_box-title'>15,000 AVX prize draw {"( for all )"}</div>
            <div className='dv_box2_box-news'>Take part in the events and sweepstakes that are launched daily on discord, the requirements are minimal and we won't ask for any purchases. find out more on discord.</div>
          </div>

          <div>
            <div className='dv_box2_box-title'>Formation of provincial groups {"( for all )"}</div>
            <div className='dv_box2_box-news'>If you know two languages, one of which is your native language and English, you can become a member of a provincial group, find out more on discord.</div>
          </div>

          <div>
            <div className='dv_box2_box-title'>Seacode event {"( for all )"}</div>
            <div className='dv_box2_box-news'>seacode is a reward multiplier that aims to benefit and reward people or companies that promote Avaland sea through affiliations, find out more on discord.</div>
          </div>

          <div>
            <div className='dv_box2_box-title'>I am history {"( produce content )"}</div>
            <div className='dv_box2_box-news'>The “I am History” event seeks to greatly reward the Afintrians who first believed in Avaland Sea with absurd rewards. For example, the Top 10 Youtubers in each country will win the title “I am History”, and great rewards. Find out more on discord.</div>
          </div>

          <div>
            <div className='dv_box2_box-title'>Become a contributor  {"( for all )"}</div>
            <div className='dv_box2_box-news'>We have tasks from the simplest to the most complex, anyone can become a member of the team and the evaluation process is open and standardized.</div>
          </div>

          <div>
            <div className='dv_box2_box-title'>private-sale {"( for big players )"}</div>
            <div className='dv_box2_box-news'>Make large purchases of AVX by requesting a personalized purchase, remembering that the release of large purchases will be gradual so as not to disrupt the avaland sea economy.</div>
          </div>
        </div>
      </div>

      <div className='dv-box-1'>
        <div className='dv-box-1-bt-1'>Discord</div>
        <div className='dv-box-1-bt-1'>Telegram</div>
        <div className='dv-box-1-bt-1'>Whitepapper</div>
      </div>

      <div className='dv-title-3'>{"( To participate in the public sale of AVX you must have the premium )"}</div>
    </div>
  );
}

export default Home;