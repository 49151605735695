// Home.js
import React,{useState} from 'react';

import '../../css/panel/seacode-panel.css'

function Home() {

  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  const[stt,setStt] = useState('in')

  function sttSalt () {
    if(stt == 'in'){
      setStt('out');
    }
    else if(stt == 'out'){
      setStt('in');
    }
  }
  return (
    <div id="seacode-panel-view" className='seacode-panel-view' onContextMenu={handleContextMenu}>
      
      <div className='spv-container-1'>
        <div className='spv-c-1-title'>share your seacode and win!</div>
        <div className='spv-c-1-sub-title'>Reveal you seacode buying the Premium</div>
      </div>

      <div className='spv-container-2'>
        <div className='spv-c-2-box-1'>
          <div className='spv-c2-b1-title-1'>You Seacode</div>
          <div className="spv-c2-b1-value">0x00017431</div>
          <div className="spv-c2-b1-title-2">Link to a seacode</div>
          <input className="spv-c2-b1-input" placeholder='Enter the seacode'></input>
          <div className="spv-c2-b1-title-3">{"( Your seacode is not allowed )"}</div>
          <div className="spv-c2-b1-bt">Confirm</div>
        </div>

        <div className='spv-c-2-box-2'>
          <div className='spv-c-2-b-2-value'>72</div>
          <div className='spv-c-2-b-2-describ'>Total Affiliate</div>
          <div className='spv-c-2-b-2-bt'>Exchange</div>
          <div className='spv-c-2-b-2-text'>Exchange your affiliate points for AVX, each affiliate point is worth 2 AVX.</div>
        </div>

        <div className='spv-c-2-box-3'>
          

          { stt === 'out' ?
          <>
            <div className='spv-c2-b3r-title'>
              <div className='spv-c2-b3r-back' onClick={sttSalt}>{"<"}</div>
              <div>Results</div>
            </div>
            <div className='spv-c2-b3r-sub-title-1'>
              <div>Affiliate points</div>
              <div>72</div>
            </div>
            <div className='spv-c2-b3r-sub-title-1'>
              <div>AVX earn</div>
              <div>144</div>
            </div>
            {/* <div className='spv-c2-b3r-bt' onClick={sttSalt}>Back</div>  */}
            <div className='spv-c2-b3r-title-2'>Conversion Rate</div>
            <div className='spv-c2-b3r-title-3'>{"Reference: Google Clound Bigdata"}</div>
            
            <div className='spv-c2-b3r-box'>

              <div className='spv-c2-b3r-sub-title-3'>
                <div>Invite Friends</div>
                <div>27.1 ~ 69.4%</div>
              </div>
              <div className='spv-c2-b3r-sub-title-3'>
                <div>Youtube Video</div>
                <div>0.29 ~ 8.64%</div>
              </div>
              <div className='spv-c2-b3r-sub-title-3'>
                <div>Google ADS</div>
                <div>0.68 ~ 3.11%</div>
              </div>
              <div className='spv-c2-b3r-sub-title-3'>
                <div>Facebook ADS</div>
                <div>0.29 ~ 2.47%</div>
              </div>
              <div className='spv-c2-b3r-sub-title-3'>
                <div>Facebook</div>
                <div>0.15 ~ 1.75%</div>
              </div>
              <div className='spv-c2-b3r-sub-title-3'>
                <div>Instagram</div>
                <div>0.40 ~ 1.79%</div>
                </div>
              <div className='spv-c2-b3r-sub-title-3'>
                <div>Twitter X</div>
                <div>0.16 ~ 0.99%</div>
              </div>
            </div>
          </> 
          : stt === 'in' ?
          <>
            <div className='spv-c2-b3-title'>Simulator</div>
            <div className='spv-c2-b3-sub-title-1'>Views / Per day</div>
            <input className='spv-c2-b3-input'></input>
            <div className='spv-c2-b3-sub-title-2'>Conversion rate</div>
            <input className='spv-c2-b3-input' placeholder='%'></input>
            <div className='spv-c2-b3-sub-title-3'>Number of days</div>
            <input className='spv-c2-b3-input'></input>
            <div className='spv-c2-b3-bt' onClick={sttSalt}>Simulate</div>
          </>
          : null
          }
          
        </div>

      </div>

    </div>
  );
}

export default Home;