// Avx.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './css/Avx.css'

import Avx1 from "./struct/avx/avx-1"
import Avx2 from "./struct/avx/avx-2"
import Avx3 from "./struct/avx/avx-3"
import Avx4 from "./struct/avx/avx-4"
import Avx5 from "./struct/avx/avx-5"

function Avx() {
  // const navigate = useNavigate();
  // const hasPermission = true; 

  // useEffect(() => {
  //   if (!hasPermission) {
  //     navigate('/');
  //   }
  // }, [hasPermission, navigate]);

  // if (!hasPermission) {
  //   return null;
  // }

  useEffect(() => {
    let scrollTimeout;

    const handleScroll = (event) => {
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
      scrollTimeout = setTimeout(() => {
        const scrollPosition = window.scrollY;
        const screenHeight = window.innerHeight;
        const componentIndex = Math.round(scrollPosition / screenHeight);
        window.scrollTo({
          top: componentIndex * screenHeight,
          behavior: 'smooth',
        });
      }, 50);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  

  return (
    <div>
      <Avx1 />
      <Avx2 />
      <Avx3 />
      <Avx4 />
      <Avx5 />
      </div>
  );
}

export default Avx;
