// Home.js
import React,{useState} from 'react';
import { Link, useLocation } from 'react-router-dom';

import "../../css/seacode/seacode-1.css"

function Home() {
  const location = useLocation();

  const handleContextMenu = (event) => {
    event.preventDefault();
  };
  return (
    <div id="seacode-1" className='seacode-1' onContextMenu={handleContextMenu}>
      <Link className='h-1-icon-avx' to="/"></Link>
      <Link className='h-1-bt-play-avx dcc' to="/panel">CONNECT WALLET</Link>
      <div className='h-1-bt-whitepaper'></div>

      <nav className='nav-link location-nav-link-avx'>
        <Link className={`h-1-nav-link ${location.pathname === '/public-sale' ? 'active' : ''}`} to="/public-sale">Public sale 🔥</Link>
        <Link to="/avx" className={`h-1-nav-link ${location.pathname === '/avx' ? 'active' : ''}`}>AVX</Link>
        <Link className={`h-1-nav-link ${location.pathname === '/seacode' ? 'active' : ''}`} to="/seacode">Seacode</Link>
        <a href="https://www.google.com" className='h-1-nav-link'  target="_blank" rel="noopener noreferrer">Whitepaper</a>
      </nav>

      
    </div>
  );
}

export default Home;