// Home.js
import React,{useState} from 'react';

import '../../css/panel/public-sale-inter.css'


function Home() {

  const handleContextMenu = (event) => {
    event.preventDefault();
  };
  return (
    <div id="public-sale-view" className='public-sale-view' onContextMenu={handleContextMenu}>

      <div className='psv-box-price'>
        {/* <div className='psv-bp-avx-icon'></div> */}
        <div className='psv-bp-title-1'>0.17 MATIC 
          <a className='psv-bp-sub-title-1'>{"( PER AVX )"}</a>
        </div>
        </div>
      

      <div className='psv-box-buy-ps'>

        <div className='psv-bb-avxc'>
          <div className='psv-bba-box-1'>
          <div className='psv-bba-b1-status'>
            <div>Not permit</div>
            <div className='psv-bba-b1s-info'>i</div>
          </div>
          <div className='psv-bba-b1-describ'>PURCHASE AVX</div>
          <input className='psv-bba-b1-input' placeholder='Enter value MATIC'></input>
          <div className='psv-bba-b1-status-2'>Minimum Order: 0.34 MTC</div>
          <div className='psv-bba-b1-status-2'>{"( 0 AVX )"}</div>
            <div className='psv-bba-b1-bt'>Confirm</div>
          </div>

          <div className='psv-bba-box-2'>
            <div className='psv-bba-b1-icon'></div>

          </div>

        </div>
        
        <div className='psv-bb-demigod'>
          <div className='psv-bb-d-status'>
            <div>Sales open {"(3115/3500)"}</div>
            <div className='psv-bba-b1s-info'>i</div>
          </div>
          <div className='psv-bb-d-title'>Demigod</div>
          <div className='psv-bb-d-icon'></div>
          <div className='psv-bb-d-describ'>130 MTC</div>
          <div className='psv-bb-d-box1'>
            <input className='psv-bb-d-input' placeholder='Enter the amount'></input>

            <div className='psv-bb-d-bt'>Buy</div>
          </div>
        </div>
        
        <div className='psv-bb-ark'>
        <div className='psv-bb-d-status'>
          <div>Sales open {"(36185/40000)"}</div>
          <div className='psv-bba-b1s-info'>i</div>
        </div>
          <div className='psv-bb-d-title'>Ark</div>
          <div className='psv-bb-ark-icon'></div>
          <div className='psv-bb-d-describ'>10 MTC</div>
          <div className='psv-bb-d-box1'>
            <input className='psv-bb-d-input' placeholder='Enter the amount'></input>

            <div className='psv-bb-d-bt'>Buy</div>
          </div>
        </div>    
      
      </div>

      <div className='psv-box-growth-33'>
        <div>You can't take part in the initial sale AVX because you don't have a premium. </div>
      </div>
    
    </div>
  );
}

export default Home;