// Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

function Home() {
  return (
    <div className='home-9 dcc'>
      <a href="https://www.google.com" className='h-9-bt-1'  target="_blank" rel="noopener noreferrer">Discord</a>
      {/* <Link className='h-9-icon' to="#"></Link> */}
      <ScrollLink className='h-9-icon' to="home-1" smooth={true} duration={500}>
        
      </ScrollLink>

      <div className='h-9-end-navbar'>
        <Link className='h-1-nav-link' to="/avx">AVX</Link>
        <Link className='h-1-nav-link' to="/public-sale">Public sale</Link>
        <Link className='h-1-nav-link' to="/seacode">Seacode</Link>
        <a href="https://www.google.com" className='h-1-nav-link'  target="_blank" rel="noopener noreferrer">Discord</a>
        <a href="https://www.google.com" className='h-1-nav-link'  target="_blank" rel="noopener noreferrer">Whitepaper</a>
      </div>
      <Link className='h-9-bt-connect' to="/panel">Connect wallet</Link>
    </div>
  );
}

export default Home;