// Home.js
import React,{useState, useEffect} from 'react';

import Dashboard from './dashboard'
import PublicSaleInter from './public-sale-inter'
import SeacodePanel from './seacode-panel'
import Scopewallet from './scope-wallet'

function Home() {

  const handleContextMenu = (event) => {
    event.preventDefault();
  };

    const [activeComponent, setActiveComponent] = useState('dashboard');

    const[selectLink, setSelectLink] = useState(
      ["pnl-n1-link pnl-n1-link-select",
        "pnl-n1-link",
        "pnl-n1-link",
        "pnl-n1-link"
      ]
    );


    useEffect(() => {
      switch (activeComponent) {
        case 'dashboard':
          setSelectLink([
            "pnl-n1-link pnl-n1-link-select",
            "pnl-n1-link",
            "pnl-n1-link",
            "pnl-n1-link"
          ]);
          break;
        case 'public-sale-inter':
          setSelectLink([
            "pnl-n1-link",
            "pnl-n1-link pnl-n1-link-select",
            "pnl-n1-link",
            "pnl-n1-link"
          ]);
          break;
        case 'seacode-panel':
          setSelectLink([
            "pnl-n1-link",
            "pnl-n1-link",
            "pnl-n1-link pnl-n1-link-select",
            "pnl-n1-link"
          ]);
          break;
        case 'scopewallet':
          setSelectLink([
            "pnl-n1-link",
            "pnl-n1-link",
            "pnl-n1-link",
            "pnl-n1-link pnl-n1-link-select"
          ]);
          break;
        default:
          setSelectLink([
            "pnl-n1-link",
            "pnl-n1-link",
            "pnl-n1-link",
            "pnl-n1-link"
          ]);
      }
    }, [activeComponent]);

    const renderComponent = () => {
      switch (activeComponent) {
        case 'public-sale-inter':
          return <PublicSaleInter />;
        case 'seacode-panel':
          return <SeacodePanel />;
        case 'scopewallet':
          return <Scopewallet />
        default:
          return <Dashboard />;
      };
  }


  return (
    <div id="panel-1" className='panel-1' onContextMenu={handleContextMenu}>
      <div className='panel-nav-left'>

        <div className='pnl-logo'></div>

        <div className='pnl-nav-1'>
          <div className={selectLink[0]} onClick={ () => setActiveComponent('dashboard')}>DASHBOARD</div>
          <div className={selectLink[1]} onClick={ () => setActiveComponent('public-sale-inter')}>PUBLIC SALE</div>
          <div className={selectLink[2]} onClick={ () => setActiveComponent('seacode-panel')}>SEACODE PANEL</div>
          <div className={selectLink[3]} onClick={ () => setActiveComponent('scopewallet')}>STORAGE</div>
          <div className='pnl-n1-link block-link'>EXCHANGE</div>
          <div className='pnl-n1-link block-link'>GAME FARM</div>
          <div className='pnl-n1-link block-link'>GAME BATTLE</div>
          <div className='pnl-n1-link block-link'>TRIALY QUEST</div>
          <div className='pnl-n1-link block-link'>ROUND BET</div>
          <div className='pnl-n1-link block-link'>SCHOLARSHIP</div>
          <div className='pnl-n1-link block-link'>MARKETPLACE</div>
          <div className='pnl-n1-link block-link'>CREDIT</div>
          <div className='pnl-n1-link block-link'>STACKING</div>
          <div className='pnl-n1-link block-link'>TREASURY</div>
          <div className='pnl-n1-link block-link link-end'>Avaland Stock Exchange</div>
        </div>

        <div className='pnl-seacode-box'>
          <div className='pnl-s-growth-2'> Share and earn!</div>
          <div className='pnl-s-box-1'>
            <div className='pnl-s-value'>0x000 has no</div>
            <div className='pnl-s-bt-copy'></div>
          </div>
          <div className='pnl-s-growth-1'> Multiply you earn sharing you seacode.</div>
          <div className='pnl-s-growth-1'> Reveive 3 AVX per affiliate.</div>

        </div>

      </div>
      <div className='panel-nav-top'>

        <div className='pnt-box-premium'>
          <div className='pnt-status-premium-1'>Premium</div>
          <div className='pnt-bt-premium'>buy</div>
        </div>

        <div className='pnt-box-AVXC'>
          <div className='pnt-value'>4965</div>
          <div className='pnt-AVXC-logo'></div>
        </div>

        <div className='pnt-box-GLP'>
          <div className='pnt-value'>0</div>
          <div className='pnt-GLP-logo'></div>

        </div>

      </div>
      <div className='panel-viewport'>
        {renderComponent()}
      </div>
      

      
    </div>
  );
}

export default Home;