// ModalComponent.js
import React from 'react';
import Modal from 'react-modal';
import './modal-share.css'

// Modal.setAppElement('#root');

function ModalComponent({ isOpen, onRequestClose }) {
  return (
    <Modal className='modal-share'
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <h2 className='m-1-title'>Share and invite your friends ❤</h2>
      <button className='m-1-bt-twitter' onClick={onRequestClose}>X Twitter</button>
      <button className='m-1-bt-facebook' onClick={onRequestClose}>Facebook</button>
      <button className='m-1-bt-linkedin'onClick={onRequestClose}>Linkedin</button>
      <button className='m-1-bt-instagram'onClick={onRequestClose}>Instagram</button>


      <button className='m-1-bt-close' onClick={onRequestClose}></button>
    </Modal>
  );
}

export default ModalComponent;
