// Avx.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import PublicSale1 from './struct/public-sale/public-sale-1'
import PublicSale2 from './struct/public-sale/public-sale-2'

import './css/public-sale/public-sale-1.css'
import './css/public-sale/public-sale-2.css'

function PublicSale() {
  useEffect(() => {
    let scrollTimeout;

    const handleScroll = (event) => {
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
      scrollTimeout = setTimeout(() => {
        const scrollPosition = window.scrollY;
        const screenHeight = window.innerHeight;
        const componentIndex = Math.round(scrollPosition / screenHeight);
        window.scrollTo({
          top: componentIndex * screenHeight,
          behavior: 'smooth',
        });
      }, 50);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  

  return (
    <div>
      <PublicSale1/>
      <PublicSale2/>
    </div>
  );
}

export default PublicSale;
