// Avx.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './css/Avx.css'

import './css/seacode/seacode-1.css'
import './css/seacode/seacode-2.css'
import './css/seacode/seacode-3.css'

import Seacode1 from './struct/seacode/seacode-1'
import Seacode2 from './struct/seacode/seacode-2'
import Seacode3 from './struct/seacode/seacode-3'

function Seacode() {
  const navigate = useNavigate();
  const hasPermission = true; 

  useEffect(() => {
    if (!hasPermission) {
      navigate('/');
    }
  }, [hasPermission, navigate]);

  useEffect(() => {
    let scrollTimeout;

    const handleScroll = (event) => {
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
      scrollTimeout = setTimeout(() => {
        const scrollPosition = window.scrollY;
        const screenHeight = window.innerHeight;
        const componentIndex = Math.round(scrollPosition / screenHeight);
        window.scrollTo({
          top: componentIndex * screenHeight,
          behavior: 'smooth',
        });
      }, 50);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (!hasPermission) {
    return null;
  }

  return (
    <div>
      <Seacode1 />
      <Seacode2 />
      <Seacode3 />
      </div>
  );
}

export default Seacode;
