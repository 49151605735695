// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import PublicSale from './Public-sale';
import Avx from './Avx';
import Seacode from './Seacode';
import Panel from './Panel'
import './css/App.css'
import './css/Navbar.css'


//--
 import './css/Home.css'
 import './css/home/home-1.css'
 import './css/home/home-3.css'
 import './css/home/home-4.css'
 import './css/home/home-5.css'
 import './css/home/home-6.css'
 import './css/home/home-9.css'
//--

function App() {
  
  


  return (
    <Router >
     

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/public-sale" element={<PublicSale />} />
        <Route path="/avx" element={<Avx />} />
        <Route path="/seacode" element={<Seacode />} />
        <Route path="/panel" element={<Panel />} />
      </Routes>

    </Router>
  );
}

export default App;
