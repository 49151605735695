// Home.js
import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className='home-4 dcc'>
      <Link className='h-4-bt-1' to="/seacode">Whitepaper</Link>
      <a href="https://www.google.com" className='h-4-bt-1'  target="_blank" rel="noopener noreferrer">Whitepaper</a>

      
    </div>
  );
}

export default Home;