// Home.js
import React,{useEffect} from 'react';


import Home1 from './struct/home/home-1'
import Home3 from './struct/home/home-3'
import Home4 from './struct/home/home-4'
import Home5 from './struct/home/home-5'
import Home6 from './struct/home/home-6'
import Home9 from './struct/home/home-9'


function Home() {
  
  useEffect(() => {
    let scrollTimeout;

    const handleScroll = (event) => {
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
      scrollTimeout = setTimeout(() => {
        const scrollPosition = window.scrollY;
        const screenHeight = window.innerHeight;
        const componentIndex = Math.round(scrollPosition / screenHeight);
        window.scrollTo({
          top: componentIndex * screenHeight,
          behavior: 'smooth',
        });
      }, 50);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='home-body'>
      <Home1/>
      <Home3/>
      <Home4/>
      <Home5/>
      <Home6/>
      <Home9/>
    </div>
  );
}

export default Home;
