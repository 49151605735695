// Home.js
import React,{useState} from 'react';
import { Link } from 'react-router-dom';

import "../../css/avx/avx-3.css"

function Home() {

  const handleContextMenu = (event) => {
    event.preventDefault();
  };
  return (
    <div id="avx-3" className='avx-3' onContextMenu={handleContextMenu}>
      

      
    </div>
  );
}

export default Home;