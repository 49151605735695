// Home.js
import React,{useState} from 'react';
import { Link, useLocation } from 'react-router-dom';

import "../../css/seacode/seacode-1.css"

function Home() {
  const location = useLocation();

  const handleContextMenu = (event) => {
    event.preventDefault();
  };
  return (
    <div id="public-sale-2" className='public-sale-2' onContextMenu={handleContextMenu}>

      
    </div>
  );
}

export default Home;