// Home.js
import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import ModalShare from '../../component/ModalShare'
import ModalWhitelist from '../../component/ModalWhitelist'
import ModalBuyDemigod from '../../component/ModalBuyDemigod'

function Home() {
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);
  const [modalIsOpen3, setModalIsOpen3] = useState(false);

  const handleContextMenu = (event) => {
    event.preventDefault();
  };
  return (
    <div id="home-1" className='home-1' onContextMenu={handleContextMenu}>
      <Link className='h-1-icon' to="/"></Link>
      <div className='h-1-skade'></div>
      <Link className='h-1-bt-play dcc' to="/panel">CONNECT WALLET</Link>
      <div className='h-1-bt-whitepaper'></div>

      <div className='h-1-bt-share' onClick={() => setModalIsOpen1(true)}></div>
      <ModalShare isOpen={modalIsOpen1} onRequestClose={() => setModalIsOpen1(false)} />
      <ModalWhitelist isOpen={modalIsOpen2} onRequestClose={() => setModalIsOpen2(false)} />
      <ModalBuyDemigod isOpen={modalIsOpen3} onRequestClose={() => setModalIsOpen3(false)} />
      <a href="https://www.google.com" className='h-1-bt-discord'  target="_blank" rel="noopener noreferrer" />

      <div className='h-1-group-1'>
        {/* <div className='h-1-bt-reveal-seacode'>Reveal seacode</div> */}
        <div className='h-1-bt-reveal-seacode' onClick={() => setModalIsOpen2(true)}>Join the public sale list</div>
        <div className='h-1-bt-reveal-avx' onClick={() => setModalIsOpen3(true)}>Buy Demigods</div>

      </div>
      
      <div className='nav-link location-nav-link'>
        <Link className='h-1-nav-link' to="/public-sale">Public sale 🔥</Link>
        <Link className='h-1-nav-link' to="/avx">AVX</Link>
        <Link className='h-1-nav-link' to="/seacode">Seacode</Link>
        <a href="https://www.google.com" className='h-1-nav-link'  target="_blank" rel="noopener noreferrer">Whitepaper</a>

      </div>
      
    </div>
  );
}

export default Home;