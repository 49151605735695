// Home.js
import React,{useState} from 'react';
import { Link } from 'react-router-dom';

import "../../css/avx/avx-4.css"

function Home() {

  const handleContextMenu = (event) => {
    event.preventDefault();
  };
  return (
    <div id="avx-4" className='avx-4' onContextMenu={handleContextMenu}>
      <div className='a-4-box-whitepaper'><a href="https://www.google.com" className='a-4-link-whitepaper'  target="_blank" rel="noopener noreferrer">Whitepaper</a></div>

      
    </div>
  );
}

export default Home;