// Home.js
import React from 'react';
// import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className='home-6 dcc'>
      
    </div>
  );
}

export default Home;