// Home.js
import React,{useState} from 'react';

import '../../css/panel/scope-wallet.css'


function Home() {

  const handleContextMenu = (event) => {
    event.preventDefault();
  };
  return (
    <div id="scope-wallet-view" className='scope-wallet-view' onContextMenu={handleContextMenu}>
      <div className='swv-box-1'>
        <div className='swv-b1-title'>Storage</div>
        <div className='swv-b1-bag'>
          <div className='swv-b1-bag-box'>
            <div className='swv-b1-bb-value'>0</div>
            <div className='swv-b1-bb-img'></div>
            <div className='swv-b1-bb-title'>Demigod</div>
            <div className='swv-b1-bb-box-bt'>
              <div className='swv-b1-bb-bbt-1'></div>
              <div className='swv-b1-bb-bbt-1'></div>
            </div>
          </div>
          <div className='swv-b1-bag-box'>
            <div className='swv-b1-bb-value'>0</div>
            <div className='swv-b1-bb-img-2'></div>
            <div className='swv-b1-bb-title'>Chest</div>
            <div className='swv-b1-bb-box-bt'>
              <div className='swv-b1-bb-bbt-1'></div>
              <div className='swv-b1-bb-bbt-1'></div>
            </div>
          </div>
          <div className='swv-b1-bag-box'></div>
          <div className='swv-b1-bag-box'></div>
          <div className='swv-b1-bag-box'></div>
          <div className='swv-b1-bag-box'></div>
          <div className='swv-b1-bag-box'></div>
          <div className='swv-b1-bag-box'></div>
          <div className='swv-b1-bag-box'></div>
          <div className='swv-b1-bag-box'></div>
          <div className='swv-b1-bag-box'></div>
          <div className='swv-b1-bag-box'></div>
          <div className='swv-b1-bag-box'></div>
          <div className='swv-b1-bag-box'></div>
          <div className='swv-b1-bag-box'></div>
          <div className='swv-b1-bag-box'></div>
        </div>
      </div>

      <div className='swv-box-2'>

        <div className='swv-b2-bar-2'>
          
            <div className='swv-b2-b1-box-stitle-2'>Panel wallet</div>

        </div>

        <div className='swv-b2-bar-1'>

          <div className='swv-b2-b1-box'>
            <div className='swv-b2-b1-box-value-1'>0</div>
            <div className='swv-b2-b1-box-stitle-1'>Total Skade</div>
          </div>

          <div className='swv-b2-b1-box'>
            <div className='swv-b2-b1-box-value-1'>0</div>
            <div className='swv-b2-b1-box-stitle-1'>In sale</div>
          </div>
          <div className='swv-b2-b1-box'>
            <div className='swv-b2-b1-box-value-1'>0</div>
            <div className='swv-b2-b1-box-stitle-1'>In scholar</div>
          </div>

        </div>

        <div className='swv-b2-bar-1'>

          <div className='swv-b2-b1-box'>
            <div className='swv-b2-b1-box-value-1'>0</div>
            <div className='swv-b2-b1-box-stitle-1'>GLP Reward</div>
          </div>

          <div className='swv-b2-b1-box'>
            <div className='swv-b2-b1-box-value-1'>0</div>
            <div className='swv-b2-b1-box-stitle-1'>In 1 Month</div>
          </div>
          <div className='swv-b2-b1-box'>
            <div className='swv-b2-b1-box-value-1'>0</div>
            <div className='swv-b2-b1-box-stitle-1'>Tip GLP</div>
          </div>
        </div>

        <div className='swv-b2-bar-1'>

          <div className='swv-b2-b1-box'>
            <div className='swv-b2-b1-box-value-1'>0</div>
            <div className='swv-b2-b1-box-stitle-1'>Credit{" (GCI)"}</div>
          </div>

          <div className='swv-b2-b1-box'>
            <div className='swv-b2-b1-box-value-1'>0</div>
            <div className='swv-b2-b1-box-stitle-1'>Funding</div>
          </div>

          <div className='swv-b2-b1-box'>
            <div className='swv-b2-b1-box-value-1'>0</div>
            <div className='swv-b2-b1-box-stitle-1'>Scholarship</div>
          </div>


        </div>
 
        <div className='swv-b2-bar-1'>

          <div className='swv-b2-b1-box'>
            <div className='swv-b2-b1-box-value-1'>0</div>
            <div className='swv-b2-b1-box-stitle-1'>Total Stacking</div>
          </div>

          <div className='swv-b2-b1-box'>
            <div className='swv-b2-b1-box-value-1'>0</div>
            <div className='swv-b2-b1-box-stitle-1'>Total Breeding</div>
          </div>

          <div className='swv-b2-b1-box'>
            <div className='swv-b2-b1-box-value-1'>0</div>
            <div className='swv-b2-b1-box-stitle-1'>ASEX</div>
          </div>


        </div>

      </div>    
    </div>
  );
}

export default Home;