// Home.js
import React,{useState} from 'react';
import { Link } from 'react-router-dom';

import "../../css/avx/avx-2.css"

function Home() {

  const handleContextMenu = (event) => {
    event.preventDefault();
  };

    const [copied, setCopied] = useState(false);
  
    const copyToClipboard = async () => {
      try {
        await navigator.clipboard.writeText("0x31471e0791fcdbe82fbf4c44943255e923f1b794");
        setCopied(true);
        setTimeout(() => setCopied(false), 3000); // Mensagem desaparece após 3 segundos
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    };
  return (
    <div id="avx-2" className='avx-2' onContextMenu={handleContextMenu}>

    <div className='a-2-box-address'>
      <div className='a-2-box-title'>Address</div>
      <div className='a-2-box-address-value'>0x31471e0791fcdbe82fbf4c44943255e923f1b794</div>
      <div className='a-2-bt-copy' onClick={copyToClipboard}></div>

      {copied && <div className="copy-feedback">Success copied!</div>}

    </div>
      

      
    </div>
  );
}

export default Home;